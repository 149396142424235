<template>
  <Math1 v-if="widthScreen"></Math1>
  <Math2 v-else></Math2>
</template>

<script>
import Math1 from './Math1.vue'
import Math2 from './Math2.vue'

export default {
  components: { Math1, Math2 },
  data() {
    return {
      widthScreen: false,
    }
  },
  created() {
    document.title = '九章大模型(MathGPT)'
    if (window.screen.width > 1920) this.widthScreen = true
  },
}
</script>

<style lang="less" scoped></style>
