<template>
  <div class="content">
    <span v-show="lang === 'chinese'">此次开源的TAL-SCQ5K-CN 和 TAL-SCQ5K-EN数据集(各3K训练集和2K测试集)，题目为单选形式，涉及小初高阶段数学内容，带有详细的解析步骤便于进行COT的训练。</span>
    <span v-show="lang === 'english'">This release includes two open-source datasets of Chinese and English mathematical competition questions, each consisting of 5K items (3K training and 2K testing). The questions are in the form of multiple-choice and cover mathematical topics at the primary, junior high, and high school levels. Detailed solution steps are provided to facilitate COT training.</span>
    <div class="buttonTab">
      <div class="button" @click="download('huggingface')"><img src="../assets/math/huggingface.png"><span>huggingface/TAL-SCQ5K</span></div>
      <div class="button shortButton" @click="download('github')"><img src="../assets/math/github.png"><span>github/TAL-SCQ5K</span></div>
    </div>
    <div>
      <img class="backgroundImg" v-if="widthScreen" style="width: 1500px" :src="require(lang === 'chinese' ? '../assets/math/tablePcEn.png' : '../assets/math/tablePcCn.png')">
      <img class="backgroundImg" v-else :src="require(lang === 'chinese' ? '../assets/math/tablePcEn.png' : '../assets/math/tablePcCn.png')">
    </div>
  </div>
</template>
<script>
// import {Image} from "element-ui";

export default {
  name: "MathContent",
  data(){
    return {
      widthScreen: false
    }
  },
  props: {
    lang: String
  },
  components:{
    // Image
  },
  methods:{
    download(result){
      window.open(result === 'github' ? 'https://github.com/math-eval/TAL-SCQ5K' : 'https://huggingface.co/datasets/math-eval/TAL-SCQ5K')
    }
  },
  created(){
    if (window.screen.width > 1920) this.widthScreen = true;
  }
}
</script>

<style scoped lang="less">
.content{
  position: relative;
  z-index: 101;
  .buttonTab{
    margin-top: 50px;
  }
}

.button{
  :hover{
    opacity:0.4
  }
  font-weight: 400;
  text-align: center;
  font-family: PingFang SC;
  //float: left;
  width: calc(90 / 375 * 100vw);
  //text-decoration-line: underline;
  //padding: 0 40px;
  font-size: 30px;
  //margin-right: 100px;
  border-radius: 30px;
  line-height: 95px;
  //border: 2px solid rgba(255, 255, 255, 0.5);
  user-select: none;
  cursor: pointer;
  display: flex;
  //justify-content: center;
  align-items: center;
  img{
    margin-right: 20px;
    width: 45px;
    pointer-events: none;
  }
  span{
    //pointer-events: none;
    position: relative;
    &::after {
      content: "";
      position: absolute;
      left: 0;
      bottom: 20px;
      width: 100%;
      height: 2px;
      background-color: white; /* 设置下划线颜色 */
      margin-bottom: 3px; /* 设置下划线与文本底部的间距 */
    }
  }

}
.shortButton{
  width: calc(73 / 375 * 100vw);
}
.backgroundImg{
  width: 1500px;
  margin: 50px 0 100px 0;
}

</style>